import CharImage from './CharImage';
import DetailView from './DetailView';
import './MainWrapper.scss';
import { useEffect } from 'react';

function MainWrapper({page}) {

    useEffect(() => {
        document.title = page.pageTitle;
      });

    return (
        <div className="app">
            <CharImage character={page} />
            <DetailView detail={page} />
        </div>
    );
  }

export default MainWrapper;