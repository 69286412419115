import {Link} from 'react-router-dom';
import './DetailView.scss';

function DetailView({detail}) {
    return (
        <div className="text-wrapper">
            <h1 className="title">{detail.title}</h1>
            <div className="more-detail" dangerouslySetInnerHTML={{__html: detail.content}}>
            </div>
            <nav>
                <ul>
                    <li><Link to="/">About</Link></li>
                    <li><Link to="work">Work</Link></li>
                    <li><Link to="contact">Contact</Link></li>
                </ul>
            </nav>
        </div>
    );
  }

export default DetailView;