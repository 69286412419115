import './CharImage.scss'

function CharImage({character}) {
    const characterToShow = character.character;
    return (
      <div className="masked-character">
        <svg viewBox="0 3 70 70">
            <pattern id="masked-image" viewBox="0 0 1100 1100" patternUnits="userSpaceOnUse" width="150%" height="150%" x="-6%" y="-48%">
                <image id="a-mask-image" href={process.env.PUBLIC_URL + 'media/images/amirhossein-rahmati.jpg'} width="1000" height="1552"/>
            </pattern>
            <text textAnchor="middle" x="50%" y="50%" dy=".35em" className="image-mask">
                {characterToShow}
            </text>
        </svg>
      </div>
    );
  }

export default CharImage;