import MainWrapper from './components/MainWrapper';
import NotFound from './components/NotFound';
import appData from './appData';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

const trackingId = "UA-156066536-1";
ReactGA.initialize(trackingId);

function App() {

  useEffect( () => {
    ReactGA.pageview(window.location.pathname + window.location.search); 
    hotjar.initialize(1184490, 6);
  });

  const mainData = appData;

  return (
      <BrowserRouter>
        <Switch>
            <Route path="/" exact>
              <MainWrapper page={mainData[0]} />
            </Route>

            <Route path="/about" exact>
              <MainWrapper page={mainData[0]} />
            </Route>

            <Route path="/contact" exact>
              <MainWrapper page={mainData[1]} />
            </Route>

            <Route path="/work" exact>
              <MainWrapper page={mainData[2]} />
            </Route>

            <Route path="/uses" exact>
              <MainWrapper page={mainData[3]} />
            </Route>

            <Route path="*" component={NotFound} />
        </Switch>
      </BrowserRouter>
  );
}

export default App;
