import './NotFound.scss';

function NotFound() {
    return (
      <>
      <div className="masked-not-found-character">
        <svg viewBox="0 0 120 120">
            <pattern id="masked-image" viewBox="0 0 1100 1100" patternUnits="userSpaceOnUse" width="150%" height="150%" x="-20%" y="-48%">
                <image id="a-mask-image" href={process.env.PUBLIC_URL + 'media/images/amirhossein-rahmati.jpg'} width="1000" height="1552"/>

            </pattern>
            <text textAnchor="middle" x="50%" y="50%" dy=".35em" className="image-mask">
                404
            </text>
        </svg>
      </div>
      <h1 className="not-found-error-message">The page you're looking for can't be found.</h1>
      </>
    );
  }

export default NotFound;